export default {
  title: '10,000 Reasons (Bless The Lord)',
  author: 'Jonas Myrin, Matt Redman',
  chordsheet: `{time: 4/4}
{tempo: 73}
{key: G}

{comment: Chorus 1a}
Bless the [C]Lord O my [G]soul, [D/F#]O my [Em]soul
[C]Worship His [G]holy    [Dsus]name  [D]
Sing like [C]never be - [Em]fore,  [C]O  [D]my  [Em]soul
I'll [C]worship Your [D]holy    [C/G]name    [G]    [C/G]    [G]

{comment: Verse 1}
The [C]sun comes [G]up, it's a [D]new day [Em]dawning
[C]   It's time to [G]sing Your [D]song a - [Em]gain
What - [C]ever may [G]pass and what - [D]ever lies be - [Em]fore me
[C]   Let me be [G]singing when the [Dsus]eve - [D]ning  [G]comes   [C/G]  [G]

{comment: Verse 2}
You're [C]rich in [G]love and You're [D]slow to [Em]anger
Your [C]name is [G]great and Your [D]heart is [Em]kind
For [C]all Your [G]goodness I will [D/F#]keep on [Em]singing
[C]   Ten thousand [G]reasons for my [Dsus]heart   [D]to    [G]find       [C/G]  [G]

{comment: Chorus 1b}
Bless the [C]Lord O my [G]soul [D/F#]O my [Em]soul
[C]Worship His [G]holy    [Dsus]name  [D]
Sing like [C]never be - [Em]fore   [C]O  [D]my  [Em]soul
(1.) I'll [C]worship Your [D]holy    [G]name   (To Ch. 1b / V3)
(2.) I'll [C]worship Your [D]holy    [Em]name (To Tag)

{comment: Verse 3}
And [C]on that [G]day when my [D/F#]strength is [Em]failing
The [C]end draws [G]near and my [D]time has [Em]come
[C]Still my [G]soul will sing Your [D/F#]praise un - [Em]ending
[C]   Ten thousand [G]years and then for - [Dsus]ev - [D]er -  [G]more    [C/G]  [G]

{comment: Tag}
[C]Worship Your [D]holy    [Em]name
Lord I'll [C]worship Your [D]holy    [C]name

{comment: Instrumental}
[|] [C]    [G]    [|] [D/F#]    [Em]    [|] [C]    [G]    [|] [Dsus]    [D]    [|]

{comment: Ending}
Sing like [C]never be - [Em]fore   [C]O  [D]my [Em]soul
I'll [C]worship Your [D]holy    [Em]name
[C]Worship Your [D]holy    [Em]name
[C]Worship Your [D]holy    [G]name`
}
