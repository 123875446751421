
import { i18n } from "@/main";
import { Component, Prop, Vue } from "vue-property-decorator";
import FButton from '@/components/Button/FButton.vue';

@Component({
    components:{
        FButton
    }
})
export default class RegisterSongStepButtons extends Vue {

    get submitLabelFinal(){
        if(this.submitLabel){
            return this.submitLabel;
        }else if (this.editMode){
            return i18n.t('G.LIT_Save');
        }else{
            return i18n.t('R.LIT_SaveAndContinue');
        }
    }

    @Prop({type: Boolean, default: false})
    editMode!: boolean;

    @Prop({ type:Boolean, default: false})
    hideSaveAndExit!: boolean;

    @Prop({ default: ''})
    submitLabel!: string;

    @Prop({default: null})
    ga!: string | null;

    onCancel(){
      this.$emit('cancel');
    }

    onSaveAndExit(){
        this.$emit('saveAndExit');
    }
}
