
import { Component, Prop, Vue } from "vue-property-decorator";
import SongPreview from './SongPreview.vue'
import FButton from '@/components/Button/FButton.vue';
import { RegisterSongService } from "@/services/RegisterSongService";
import { ProfileService } from "@/services/ProfileService";

@Component({
  components: {
    SongPreview,
    FButton
  }
})
export default class RegisterSongStep4Done extends Vue {
  store = RegisterSongService;

  get featureToggleChords(){
    return ProfileService.profile?.featureToggleChords;
  }

  @Prop({ default: false, type: Boolean})
  hideButtons!: boolean;
}
