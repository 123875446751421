
import { Component, Vue, Watch } from 'vue-property-decorator'
import ModalForm from '@/components/Modal/ModalForm.vue';
import Modal from '@/components/Modal/Modal.vue';
import FCheckbox from '@/components/Form/FCheckbox.vue';
import FButton from '@/components/Button/FButton.vue';
import Alert from '@/components/Alert/Alert.vue';
import { RegisterSongService } from '@/services/RegisterSongService';
import ChordProEditor from '@/components/ChordProEditor/ChordProEditor.vue'
import ChordProPreview from '@/components/ChordProEditor/ChordProPreview.vue'
import PhraseLink from '@/components/PhraseLink/PhraseLink.vue'
import PhraseLinkEmitClick from '@/components/PhraseLink/PhraseLinkEmitClick.vue'
import FInput from '@/components/Form/FInput.vue';
import { ChordProService } from '@/services/ChordProService';
import Directives from '@/assets/json/Directives'
import ConfirmService from '@/services/ConfirmService';
import { FELyricPart } from '@/models/frontendOnly/FELyricPart';
import ExampleChords from '@/assets/ExampleChords';
import ExampleChordsGerman from '@/assets/ExampleChordsGerman';
import { ProfileService } from '@/services/ProfileService';

const localStorageDontShowHelpKey = 'ss_add_chords_intro_help';

@Component({
  components:{
    ModalForm,
    FButton,
    ChordProEditor,
    Alert,
    Modal,
    PhraseLinkEmitClick,
    PhraseLink,
    FInput,
    ChordProPreview,
    FCheckbox
  }
})
export default class SongChordEditorModal extends Vue {
  chordproStore = ChordProService;
  store = RegisterSongService;

  stringInError = '';
  directives = Directives.filter(d => !d.rules.overwrittenDuringEditing);
  error = false;
  restrictedWordsError = false;
  invalidChordsError = false;
  generalIssuesError = '';
  editMode_nAddMode = false;
  chordsData = '';
  oldChordsData = '';
  title = '';
  authors = '';
  lyrics: FELyricPart[] = [];
  showErrors = false;
  showHelpFirstTime = true;
  dontShowAgain = false;

  get countryCode() {
    return ProfileService.profile?.org.countryCode || '';
  }

  get useGermanExample() {
    //Germany, Switzerland, Austria
    return ['DE','CH', 'AT'].includes(this.countryCode.toUpperCase());
  }


  get sampleChordsTitle() {
    return this.useGermanExample ? ExampleChordsGerman.title : ExampleChords.title;
  }

  get sampleChordsAuthor() {
    return this.useGermanExample ?  ExampleChordsGerman.author : ExampleChords.author;
  }
  get sampleChords() {
    return this.useGermanExample ?  ExampleChordsGerman.chordsheet : ExampleChords.chordsheet;
  }

  get currentRestrictedWordsErrorInEditor(){

    return this.chordproStore.restrictedWordsError;
  }

  get lyricsRef (){
    return RegisterSongService.getLyricsRef(this.lyrics)
  }

  get lyricsFromCSE() {
    return RegisterSongService.getLyricsFromCSE(this.chordsData)
  }

  get lyricsDiffer() {
    return this.lyricsRef !== this.lyricsFromCSE;
  }

  get inputValidation() {
    const angleBrackets = /[<>]/;
    return angleBrackets.test(this.chordsData);
  }

  @Watch('currentRestrictedWordsErrorInEditor')
  resetRestrictedWordsError(){
    if (this.restrictedWordsError && !this.chordproStore.restrictedWordsError){
      this.restrictedWordsError = false;
    }
    if (!this.restrictedWordsError && this.chordproStore.restrictedWordsError){
      this.restrictedWordsError = true;
    }
  }


  async setError(stringInError: string){
    this.stringInError = stringInError;
    this.restrictedWordsError = true;
    await this.$nextTick();
    (this.$refs.alert as Alert).restartAnimation();
  }


  async init(title: string, chords: string, authors: string, lyrics: FELyricPart[], editMode_nAddMode: boolean){
    this.lyrics = lyrics;
    this.title = title;
    this.authors = authors;
    this.chordsData = chords;
    this.oldChordsData = chords;
    this.editMode_nAddMode = editMode_nAddMode;
    this.dontShowAgain = false;
    this.showHelpFirstTime = false;
    ChordProService.showLyrics = this.lyricsDiffer && this.editMode_nAddMode;
    (this.$refs.modal as ModalForm).show();
    if (!this.editMode_nAddMode && !localStorage.getItem(localStorageDontShowHelpKey)){
      this.showHelpFirstTime = true;
      (this.$refs.helpModal as Modal).show();
      return;
    }

  }

  get chordsModalTitle(){
    return this.editMode_nAddMode ?
      this.$t('R.LIT_EditChords') :
      this.$t('R.LIT_AddChords')
  }

  hide(){
    (this.$refs.modal as ModalForm).hide();
  }

  async onCancel() {
    if (this.store.requireEditChordsAfter){
      this.hide();
      this.$emit('cancel');
    }else{
      if (this.oldChordsData !== this.chordsData || !this.editMode_nAddMode){
        const success = await ConfirmService.showConfirm({
          title: this.$t('R.LIT_ContinueWithoutSaving').toString(),
          message: this.$t('R.MES_LeaveWithoutSaving').toString(),
          okLabel: this.$t('R.LIT_ContinueWithoutSaving').toString(),
          cancelLabel: this.$t('G.LIT_Cancel').toString()
        });

        if (success){
          this.hide();
        }
      }else{
        this.hide();
      }
    }
  }

  closeModalAfterSave = false;

  async onSaveChords(confirmOverrideLyricsDiffer = false){
    if(this.inputValidation){
      return;
    }
    this.showErrors = false;
    this.generalIssuesError = '';
    (this.$refs.lyricDiffModal as Modal).hide();

    if (
      this.chordproStore.errorCounts ||
      this.chordproStore.missingDirectives.length ||
      this.chordproStore.missingChords
      ){
      await this.$nextTick();
      this.showErrors = true;
      return;
    }


    if (this.lyricsDiffer && !confirmOverrideLyricsDiffer){
      (this.$refs.lyricDiffModal as Modal).show();
      return;
    }


    this.stringInError = '';
    ChordProService.updateWithRestrictedWords([]);
    this.restrictedWordsError = false;
    this.invalidChordsError = false;

    const chordsData = ChordProService.removeDirectivesFromChords(this.chordsData, this.title, this.authors);
    this.$emit('save', chordsData, this.closeModalAfterSave);

  }

  refreshOldChordsData(){
    this.oldChordsData = this.chordsData;
  }

  onSaveChordsAndDontExit(){
    this.closeModalAfterSave = false;
    this.onSaveChords(false);
  }

  onSaveChordsAndExit(){
    this.closeModalAfterSave = true;
    this.onSaveChords(false);
  }

  onHelpNext(){
    if (this.dontShowAgain){
      localStorage.setItem(localStorageDontShowHelpKey, '1');
    }
    (this.$refs.helpModal as Modal).hide();
    this.dontShowAgain = false;
  }

  openHelpModal(){
    (this.$refs?.helpModal as Modal).show()
  }
}
