
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import debounce from 'lodash/debounce';

@Component
export default class FTextarea extends Vue {
  @Prop({ default: '' })
  label!: string;

  @Prop({ default: false, type: Boolean })
  required!: boolean;

  @Prop({ default: false, type: Boolean })
  hiddenLabel!: boolean;

  @Prop({ default: false, type: Boolean })
  search!: boolean;

  @Prop({ default: '', })
  placeholder!: string;

  @Prop({ default: '', })
  rows!: string;

  @Prop()
  value !: string;

  @Prop({default: false, type: Boolean})
  autogrow!: boolean;

  @Prop({default: false, type: Boolean})
  autogrowLg!: boolean;

  @Prop({default: () => ([])})
  customErrors!: string[];

  highlightedLines: number[] = [];

  get val() { 
    this.autoGrowHeight();
    return this.value;
  }

  set val(value) {
    this.$emit('input', value);
  }

  height = 100;
  async autoGrowHeight() {
    if (!this.autogrow || !this.$refs.textarea) {
      return;
    }

    this.height = 1;
    await this.$nextTick();

    if (!this.$refs.textarea){
      return;
    }

    this.height = (this.$refs.textarea as HTMLTextAreaElement).scrollHeight + 14;
  }

  @Watch('customErrors', { deep: true})
  applyCustomError(){
    if (this.$refs.textarea){
      (this.$refs.textarea as HTMLTextAreaElement).setCustomValidity(this.customErrors.length ? this.customErrors.join('\n'): '')
    }
  }

  mounted() {
    this.autoGrowHeight();
    this.applyCustomError();
  }

  onInput() {
    const lines = this.val.split('\n');
    this.highlightedLines = lines
      .map((line, index) => (line.length > 52 ? index : -1))
      .filter(index => index !== -1);
  }

  debounceInput = debounce(this.onInput, 0);

  handleEnterKey(event: KeyboardEvent) {
    const textarea = this.$refs.textarea as HTMLTextAreaElement | undefined;
    if (!textarea) {
      return;
    }
    const lines = this.val.split('\n');
    const currentLine = lines[lines.length - 1];
    if (currentLine.length > 52) {
      textarea.scrollLeft = 0;
    }
    this.autoGrowHeight();
  }

  getRectangleStyle(index: number) {
    const textarea = this.$refs.textarea as HTMLTextAreaElement | undefined;
    if (!textarea) {
      return {};
    }
    const lineHeight = parseFloat(getComputedStyle(textarea).lineHeight);
    const paddingTop = parseFloat(getComputedStyle(textarea).paddingTop);
    return {
      top: `${(paddingTop + 2) + index * lineHeight}px`,
      height: `${lineHeight - 3}px`,
      backgroundColor: 'rgb(245, 204, 215)',
      position: 'absolute',
      left: '2px',
      right: '2px',
      zIndex: '0',
    };
  }
}
