
import { Component, Vue } from 'vue-property-decorator'
import FlexCenter from '@/components/Flex/FlexCenter.vue'

  @Component({
    components: {
      FlexCenter
    }
  })
export default class Toolbar extends Vue {

}
