
import { Component, Prop, Vue } from 'vue-property-decorator'
import FButton from '@/components/Button/FButton.vue'
import Modal from '@/components/Modal/Modal.vue'
import FForm from '@/components/Form/FForm.vue';

@Component({
  components: {
    FButton,
    FForm,
    Modal
  }
})
export default class ModalForm extends Vue {

  @Prop({ default: '' })
  title!: string;

  @Prop({ default: ''})
  okTitle!: string;

  @Prop({ default: ''})
  cancelTitle!: string;

  @Prop({ default: false, type: Boolean})
  hideCancelBtn!: boolean;

  @Prop({ default: false, type: Boolean})
  hideOkBtn!: boolean;

  @Prop({ default: ''})
  size!: string;

  @Prop({ default: false, type: Boolean})
  protectCloseAction!: boolean;

  @Prop({ default: false, type: Boolean})
  hideXBtn!: boolean;

  @Prop({ default: false, type: Boolean})
  allowNesting!: boolean;

  onOk() {
    this.$emit('ok');
  }

  show(){
    (this.$refs.form as FForm).resetValidation();
    (this.$refs.modal as Modal).show();
  }

  hide(){
    (this.$refs.form as FForm).resetValidation();
    (this.$refs.modal as Modal).hide()
  }

  onSubmit(e: Event){
    if (!(this.$refs.form as FForm).hasFormError){
      this.$emit('submit', e)
    }
  }

  onCancel(e: Event) {
    this.$emit('cancel', e);
  }
}
