
import { Component, Vue } from "vue-property-decorator";
import FForm from '@/components/Form/FForm.vue';
import FInput from '@/components/Form/FInput.vue';
import FSelect from '@/components/Form/FSelect.vue';
import FButton from '@/components/Button/FButton.vue';
import CatalogHelpIcon from './CatalogHelpIcon.vue';
import ModalForm from '@/components/Modal/ModalForm.vue';
import Modal from '@/components/Modal/Modal.vue';
import Alert from '@/components/Alert/Alert.vue';
import PhraseLink from '@/components/PhraseLink/PhraseLink.vue';
import RegisterSongStepButtons from './RegisterSongStepButtons.vue';
import { SelectOption } from "@/models/SelectOption";
import { RegisterSongService } from "@/services/RegisterSongService";
import { BusyService } from "@/services/BusyService";
import { ApiService } from "@/services/ApiService";
import { ProfileService } from "@/services/ProfileService";
import { ToastService } from "@/services/ToastService";
import { CustomError } from "@/models/CustomError";
import { i18n } from "@/main";

@Component({ 
  components:{ 
    FForm, 
    FInput, 
    FButton,
    CatalogHelpIcon,
    FSelect,
    ModalForm, 
    Modal, 
    Alert, 
    PhraseLink,
    RegisterSongStepButtons
  }, 
  
})
export default class RegisterSongStep0SelectACatalog extends Vue {
  store = RegisterSongService;
  get editMode() { 
    return this.store.editMode;
  }

  newCatalogName = '';

  removeIndex = -1;

  availableCatalogs: {
    rightshareSetName: string; 
    rightshareSetVid: string;
  }[] = [];

  selectedRightShareSetVid = '';

  stringInError = '';

  async getAvailableCatalogs() { 
    if (!ProfileService.profile){ 
      throw new Error('Profile is missing')
    }
    BusyService.showBusy();
    this.availableCatalogs = await ApiService.post('/api/GetAvailableCatalogs', { organizationId: ProfileService.profile.org.id})
    BusyService.hideBusy();

    if (this.availableCatalogs.length === 1 && this.store.data?.rightshareSetDetails.length === 0){
      this.selectedRightShareSetVid = this.availableCatalogs[0].rightshareSetVid;
      this.addCatalog();
      if (this.store.data){
        this.store.data.rightshareSetDetails[0].rightsharePercent = '100';
        this.onBlurPercent(0);
      }
    }
  }

  mounted() { 
    this.getAvailableCatalogs();
  }

  get availableCatalogOptions() { 
    if (!this.store.data){ 
      return [];
    }
    let options = (this.availableCatalogs.map(c => ({ 
        value: c.rightshareSetVid, 
        label: c.rightshareSetName
      })) as SelectOption[]);

    options.unshift({value: '', label: this.$t('R.LIT_SelectCatalog').toString()});

    const removeIds = this.store.data.rightshareSetDetails.map(c => c.rightshareSetVid)
    
    if (!removeIds || !removeIds.length){
      return options;
    }

    options = options.filter(o => !removeIds.includes(o.value));
    return options;
  }

  onCreateNewCatalog() { 
    this.newCatalogName = '';
    this.stringInError = '';
    this.newCatalogDupeError = false;
    this.newCatalogDupeConflictError = false;
    this.newCatalogRestrictedWordsError = false;
    this.newCatalogError = false;
    (this.$refs.newCatalogModal as ModalForm).show();
  }

  newCatalogDupeError = false;
  newCatalogDupeConflictError = false;
  newCatalogRestrictedWordsError = false;
  newCatalogError = false;

  get newCatalogHasSpecialCharacters() { 
    const angleBrackets = /[<>]/;
    return angleBrackets.test(this.newCatalogName);
  }

  async registerNewCatalog() {
    if (this.newCatalogHasSpecialCharacters) {
      return;
    }
    BusyService.showBusy();
    this.newCatalogDupeError = false;
    this.newCatalogDupeConflictError = false;
    this.newCatalogRestrictedWordsError = false;
    this.newCatalogError = false;
    
    try {
      const response = await ApiService.post('/api/RegisterCatalog', { 
        rightshareSetName: this.newCatalogName
      }, true) as {name: string; vid: string};
      (this.$refs.newCatalogModal as ModalForm).hide();
      this.availableCatalogs.push({ 
        rightshareSetName: response.name, 
        rightshareSetVid: response.vid
      });

      this.selectedRightShareSetVid = response.vid;
      this.addCatalog();
      ToastService.successToast(this.$t('R.LIT_CreatedNewCatalog').toString())
    }catch(err){ 

      if ((err as any)?.data?.payload){
        if (typeof (this.stringInError = (err as any).data.payload) === 'string'){ 
          this.stringInError = (err as any).data.payload;
        }else {
          this.stringInError = (err as any).data.payload.join(', ');
        }
      }else{ 
        this.newCatalogError = true;
        ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
        throw new CustomError(err, false, 'Reason code not recognized');
      }
      
      if ((err as any)?.data?.publicStatusMessage === 'RESTRICTED_WORDS'){
        this.newCatalogRestrictedWordsError = true;
      }else if ((err as any)?.data?.publicStatusMessage === 'DUPLICATE_FOUND'){
        this.newCatalogDupeError = true;
      }else if ((err as any)?.data?.publicStatusMessage === 'DUPLICATE_CONFLICT'){
        this.newCatalogDupeConflictError = true;
      }else{ 
        this.newCatalogError = true;
        ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
        throw new CustomError(err, false, 'Reason code not recognized');
      }
    }
    BusyService.hideBusy();
  }

  get minPercentInvalid() { 
    return !this.editMode && this.store.data?.rightshareSetDetails.find(c => parseFloat(c.rightsharePercent) < 0.15);
  }

  onInput() { 
    this.addCatalog();
    this.selectedRightShareSetVid = '';
  }

  addCatalog() { 
    if (!this.selectedRightShareSetVid){
      return;
    }

    const catalog = this.availableCatalogs.find(c => c.rightshareSetVid === this.selectedRightShareSetVid);
    if (!catalog) { 
      return;
    }

    let percent = 100 - this.store.totalPercent;
    
    const percentRemaining = 100 - percent;

    this.store.data?.rightshareSetDetails.push({ 
      rightshareSetName: catalog.rightshareSetName,
      rightshareSetVid: catalog.rightshareSetVid,
      rightsharePercent: percent.toString(), 
      rightsharePercentRemaining: percentRemaining.toString(),
      rightshareSetIsEditable: true,
      rightsHolderVid: '',
      rightsHolderName: ''
    });

    this.selectedRightShareSetVid = '';
  }

  async onRemove(index: number){ 
    this.removeIndex = index;
    (this.$refs.confirmRemove as Modal).show();
  }

  get removeCatalogName() { 
    if (!this.store.data) { 
      return '';
    }
    if (this.removeIndex >= 0 && this.removeIndex < this.store.data.rightshareSetDetails.length){ 
      return this.store.data.rightshareSetDetails[this.removeIndex].rightshareSetName
    }else{ 
      return ''
    }
  }

  removeCatalog(){ 
    this.store.data?.rightshareSetDetails.splice(this.removeIndex, 1);
    (this.$refs.confirmRemove as Modal).hide();
  }

  async onSubmit(){ 
    await this.$nextTick();
    if ((this.$refs.form as FForm).hasFormError){ 
      return;
    }
    if (this.editMode){ 
      this.$emit('submit');
    }else{
      this.store.saveAndContinue();
    }
  }

  onBlurPercent(index: number){ 
    if (!this.store.data) { 
      return;
    }
    let percent: string | number = this.store.data.rightshareSetDetails[index].rightsharePercent;
    if (typeof percent === 'string'){ 
      percent = parseFloat(percent);
    }
    percent = Math.round(percent*10000)/10000;
    if (percent < 0.15){ 
      percent = 0.15;
    }

    if (percent > 100){ 
      percent = 100;
    }
    this.store.data.rightshareSetDetails[index].rightsharePercent = percent.toString();
  }

  convertToFloatString(val: number){ 
    return (Math.round(val*10000)/10000).toString()
  }
}
