
import { Component, Vue } from "vue-property-decorator";
import FSelect from "@/components/Form/FSelect.vue";
import FInput from "@/components/Form/FInput.vue";
import FCheckbox from "@/components/Form/FCheckbox.vue";
import FTextarea from "@/components/Form/FTextarea.vue";
import FForm from "@/components/Form/FForm.vue";
import PhraseLink from '@/components/PhraseLink/PhraseLink.vue';
import FButton from "@/components/Button/FButton.vue";
import DraggableContainer from '@/components/Draggable/DraggableContainer.vue';
import { SelectOption } from "@/models/SelectOption";
import Modal from '@/components/Modal/Modal.vue';
import ModalForm from '@/components/Modal/ModalForm.vue';
import Alert from '@/components/Alert/Alert.vue';
import HelpToggle from '@/components/HelpToggle/HelpToggle.vue';
import RegisterSongStepButtons from './RegisterSongStepButtons.vue';
import * as EditLyricFunctions from '@/functions/EditLyricFunctions';
import { RegisterSongService } from "@/services/RegisterSongService";
import { FELyricPart } from "@/models/frontendOnly/FELyricPart";
import { cloneDeep } from "lodash";
import { i18n } from "@/main";
import { CustomError } from "@/models/CustomError";
import { ToastService } from "@/services/ToastService";
import { DynamicTranslationService } from "@/services/DynamicTranslationService";
import { ProfileService } from "@/services/ProfileService";

@Component({
  components: {
    HelpToggle,
    FSelect,
    FForm,
    FButton,
    FCheckbox,
    FInput,
    FTextarea,
    Modal,
    DraggableContainer,
    ModalForm,
    PhraseLink,
    Alert,
    RegisterSongStepButtons
  },
})
export default class RegisterSongStep2EnterLyrics extends Vue {
  store = RegisterSongService;
  removeSectionIndex = 0;
  allLyrics = '';
  stringInError = '';
  generalIssuesError = '';
  error = false;
  restrictedWordsError = false;

  get languageOptions(): SelectOption[] {
     return RegisterSongService.cultures?.map(c => ({
      value: c.code,
      label: c.label
    })) || [];
  }

  get enablePunctuationOverrideFeature(){
    return RegisterSongService.enablePunctuationOverrideFeature;
  }

  get sectionHeaderOptions(): SelectOption[]{
    return [{value: '882', label: DynamicTranslationService.lyricTypeBridgeText},
    {value: '880', label: DynamicTranslationService.lyricTypeChorusText},
    {value: '887', label: DynamicTranslationService.lyricTypeDescantText},
    {value: '883', label: DynamicTranslationService.lyricTypeEndingText},
    {value: '886', label: DynamicTranslationService.lyricTypeInterludeText},
    {value: '884', label: DynamicTranslationService.lyricTypeIntroText},
    {value: '1492', label: DynamicTranslationService.lyricTypeMidSectionText},
    {value: '1511', label: DynamicTranslationService.lyricTypeOstinatoText},
    {value: '1503', label: DynamicTranslationService.lyricTypePostChorusText},
    {value: '885', label: DynamicTranslationService.lyricTypePreChorusText},
    {value: '890', label: DynamicTranslationService.lyricTypeRapText},
    {value: '1510', label: DynamicTranslationService.lyricTypeRefrainText},
    {value: '888', label: DynamicTranslationService.lyricTypeSpokenWordsText},
    {value: '1491', label: DynamicTranslationService.lyricTypeTagText},
    {value: '889', label: DynamicTranslationService.lyricTypeVampText},
    {value: '881', label: DynamicTranslationService.lyricTypeVerseText}]
  }

  get editMode() {
    return this.store.editMode;
  }
  get removeSection(){
    if (!this.store.data || !this.store.data.lyrictext) {
      return null;
    }
    if (this.removeSectionIndex >= 0 &&
      this.removeSectionIndex < this.store.data.lyrictext.length
    ){
      return this.store.data.lyrictext[this.removeSectionIndex];
    }
    return null;
  }

  onRemoveSection(index: number){
    this.removeSectionIndex = index;
    if (this.store.data?.lyrictext[index]?.LyricPartTextAll){
      (this.$refs.modal as Modal).show();
    }else{
      this.confirmRemoveSection();
    }
  }

  confirmRemoveSection(){
    this.store.data?.lyrictext.splice(this.removeSectionIndex, 1);
    (this.$refs.modal as Modal).hide();
  }

  onAddAllLyrics(){
    (this.$refs.allLyricsModal as ModalForm).show();
  }

  onSaveAllLyrics(){
    if (!this.store.data){
      return;
    }
    if (this.allLyricsValidationErrors.length){
      return;
    }
    this.store.data.lyrictext = EditLyricFunctions.importLyrics(this.store.data.lyrictext, this.allLyrics);
    this.allLyrics = '';
    (this.$refs.allLyricsModal as ModalForm).hide();
  }

  addNewSection() {
    const part = cloneDeep(EditLyricFunctions.defaultLyricPart);
    this.store.data?.lyrictext.push(part);
  }

  async onSubmit(){
    this.generalIssuesError = '';
    this.restrictedWordsError = false;

    if ((this.$refs.form as FForm).hasFormError){
      return;
    }
    this.restrictedWordsError = false;
    if (this.editMode){
      this.$emit('submit');
    }else{
      try{
        this.store.checkChordsDiffer = true;
        await this.store.saveAndContinue();
      }catch(err){
        this.handleError(err);
        await this.$nextTick();
        if (this.$refs.alert){
          (this.$refs.alert as any).$el.scrollIntoView({behavior: 'smooth'});
        }else{
          window.scrollTo({top: 0, behavior: 'smooth'});
        }
      }
    }
  }

  handleError(err: any){

    if (typeof (err as any).data.payload === 'object' && (err as any).data.payload.generalIssues?.countErrors && (err as any).data.payload.generalIssues?.logText) {
      this.generalIssuesError = (err as any).data.payload.generalIssues.logText;

    }else if (((err as any)?.data?.publicStatusMessage === 'RESTRICTED_WORDS') && ((err as any)?.data?.payload)){
      this.restrictedWordsError = true;
      if (typeof (err as any).data.payload === 'string'){
        this.stringInError = (err as any).data.payload;
      }else {
        this.stringInError = (err as any).data.payload.join(', ');
      }
    }else{
      this.error = true;
      ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
      throw new CustomError(err, false, 'Reason code not recognized');
    }
  }

  onLyricInput(){
    this.store.validateLyricParts();
  }

  onCultureSelect() {
    this.store.updateCultureSettings();
  }

  onLyricPartTypeInput(row: FELyricPart) {
    row.LyricPartType = this.sectionHeaderOptions.find(o => o.value === row.LyricPartTypeLid)?.label || '';
  }

  get allLyricsValidationErrors(){
    const errs: string[] = [];
    const lineLengths = this.allLyrics.split('\n').map(l => l.length);
      if (Math.max(...lineLengths) > RegisterSongService.maxLineLength){
        errs.push(i18n.t('R.LIT_LyricMaxCharacterLineLimit', {0: RegisterSongService.maxLineLength.toString()}).toString());
      }
      if (this.allLyrics.match(/[<>]/g)){
        errs.push(i18n.t('R.MES_InvalidLyricCharacter').toString());
      }

    return errs;
  }
}
