export default {
  title: 'Mutig komm ich vor den Thron',
  author: 'Rend Collective Simon Gottschick',
  chordsheet: `{key: G}
{tempo: 69}
{time: 4/4}

{comment: Vers 1}
[Em] Allein durch [Dsus]Gnade steh ich [C]hier
[G/B] Vor deinem [Em]Thron mein Gott bei [D]dir
[Em] Der mich er-[Dsus]löst hat lädt mich [C]ein
[G/B] Ganz nah an [Em]seinem Herz zu [D]sein
Durch-[G/B]bohrte [C]Hände [D]halten [Em]mich
[C] Ich darf bei [Dsus]dir sein ewig-[G]lich    [Gsus]    [G]

{comment: Vers 2}
[Em] Will mich mein [Dsus]Herz erneut ver-[C]damm'n
[G/B] Und Satan [Em]flößt mir Zweifel [D]ein
[Em] Hör ich die [Dsus]Stimme meines [C]Herrn
[G/B] Die Furcht muss [Em]fliehn denn ich bin [D]sein
O [G/B]preist den [C]Herrn der [D]für mich [Em]kämpft
[C] Und meine [Dsus]Seele ewig [G]schützt    [Gsus]    [G]    [D/F#]

{comment: Chorus}
[Em]Mutig komm ich [C]vor den [D]Thron
[Em]Freigesprochen [C]durch den [D]Sohn
[G/B]Dein Blut macht mich [C]rein    [D]du nennst mich ganz [Em]dein
In [C]deinen Armen [D]darf ich [G]sein            [(D/F#)]  (Zum Vers 3)

{comment: Vers 3}
[Em] Seht doch wie [Dsus]herrlich Jesus [C]ist
[G/B] Der alle [Em]Schönheit über-[D]trifft
[Em] Die Liebe [Dsus]in Person ist [C]hier
[G/B] Gerecht und [Em]treu steht er zu [D]mir
All [G/B]unser [C]Lob reicht [D]niemals [Em]aus
[C] Ihn so zu [Dsus]ehrn wie's ihm ge-[G]bührt    [Gsus]    [G]    [D/F#]

{comment: Bridge}
Das ist der [C]Grund wa-[G]rum wir [D]feiern
Wir sind be-[C]freit er [G]trug das [D]Urteil
[G/B]O    [C]preist den [G]Herrn    [D]preist den [Em7]Herrn
Er [C]hat für meine [D]Schuld be-[G]zahlt`
}
