
import { Component, Prop, Vue } from 'vue-property-decorator'
import FlexCenter from '@/components/Flex/FlexCenter.vue'
import Button from '@/components/Button/Button.vue'
import ChordProDropdown from './ChordProDropdown.vue'
import Toolbar from '@/components/Toolbar/Toolbar.vue'
import DropdownItem from '@/components/Dropdown/DropdownItem.vue'
import { ChordProService } from '@/services/ChordProService'
import ButtonGroup from '@/components/Button/ButtonGroup.vue'
import { GTMService } from '@/services/GTMService'

declare class ResizeObserver {
  constructor(fn: () => void)
  observe(target: Element): void;
  unobserve(target: Element): void;
  disconnect(): void;
}


@Component({
  components: {
    FlexCenter,
    Button,
    ChordProDropdown,
    Toolbar,
    DropdownItem,
    ButtonGroup
  }
})
export default class CPTextAreaToolbar extends Vue {
  store = ChordProService

  @Prop({ type: String})
  title!: string;

  mounted(){
    const element = document.querySelector('#chordpro-toolbar');
    if (element){
      new ResizeObserver(this.updateNumChordsToShow).observe(element);
    }else{
      console.error('no element found');
    }
    this.updateNumChordsToShow();
  }

  lastSubChordClicked = false
  onMainChordClick (chord: string) {
    if (!this.lastSubChordClicked) {
      GTMService.pushCustomEvent({
        'event': 'chordsheetInsertChord',
        'chord': chord
      });
      this.store.insert(chord)
    } else {
      this.lastSubChordClicked = false
    }
  }

  chordsLimit = 9;
  lastWidth = 0;

  get commonChords(){
    return this.store.commonChords.filter((c,i) => i < this.chordsLimit);
  }

  async updateNumChordsToShow(){
    const element = document.querySelector('#chordpro-toolbar');
    if (!element){
      this.chordsLimit = 9;
      return;
    }
    if (element.clientWidth === this.lastWidth){
      console.log('quitting - no changes', element.clientWidth);
      return;
    }
    this.lastWidth = element.clientWidth;
    const isOverflowing = () => (element.scrollWidth !== element.clientWidth);

    this.chordsLimit = 9;
    await this.$nextTick();
    console.log('isoverflowing', isOverflowing())

    while(isOverflowing() && this.chordsLimit > 3) {
      this.chordsLimit --;
      console.log('chordsLimit', this.chordsLimit);
      await this.$nextTick();
    }
  }

  onSubChordClick (chord: string) {
    GTMService.pushCustomEvent({
      'event': 'chordsheetInsertChord',
      'chord': chord
    });
    this.store.insert(chord)
    this.lastSubChordClicked = true
  }

  insertDirective(d: typeof ChordProService.availableDirectives[0]){
    GTMService.pushCustomEvent({
      'event': 'chordsheetInsertDirective',
      'directive': d.tag
    });
    if (Array.isArray(d.example)){
      this.store.insert(d.example.join('\n'));
    }else{
      this.store.insert(d.example);
    }
  }

  insertCommonChord(chord: string, index: number) {
    GTMService.pushCustomEvent({
      'event': 'chordsheetInsertCommonChord',
      'chord': chord,
      'index': index.toString()
    })
    this.store.insert(`[${chord}]`);
  }

  onColumnChange(){
    const numColumns = this.store.columns;
    GTMService.pushCustomEvent({
      'event': 'chordsheetChangeNumColumns',
      'columns': numColumns
    })
  }
}
