import { DynamicTranslationService } from "@/services/DynamicTranslationService";

export enum RequestStatus {
  Saved = 1,
  NeedsReview,
  InReview,
  Success,
  UnableToProcess
}

export enum EntryStage {
  SelectCatalog = 0,
  EnterSongDetails, // 1
  EnterLyrics, // 2
  EnterChords, // 3
  Review, // 4
  Done, // 5
  Delete // 6
}

export enum RequestType {
  RegisterSong = 1,
  AddLyrics,
  EditLyrics,
  EditSongDetails,
  EditSongOwnership,
  AddChords,
  EditChords,
}

export function getRequestTypeMsg(id: number) {
  if (id === RequestType.RegisterSong){
    return DynamicTranslationService.registerSongButtonText;
  } else if (id === RequestType.AddLyrics){
    return DynamicTranslationService.addLyricsText;
  } else if (id === RequestType.EditLyrics){
    return DynamicTranslationService.editLyricsText;
  } else if (id === RequestType.AddChords){
    return DynamicTranslationService.addChordsText;
  } else if (id === RequestType.EditChords){
    return DynamicTranslationService.editChordsText;
  } else if (id === RequestType.EditSongDetails){
    return DynamicTranslationService.editSongDetailsText;
  } else if (id === RequestType.EditSongOwnership){
    return DynamicTranslationService.registerEditSongOwnershipText;
  }else{
    return '';
  }
}

export function getRequestStatusMsg(id: number) {
  if (id === RequestStatus.Saved){
    return DynamicTranslationService.savedText;
  } else if (id === RequestStatus.NeedsReview){
    return DynamicTranslationService.needsReviewText;
  } else if (id === RequestStatus.InReview){
    return DynamicTranslationService.recentInReviewText;
  } else if (id === RequestStatus.Success){
    return DynamicTranslationService.successText;
  } else if (id === RequestStatus.UnableToProcess){
    return DynamicTranslationService.unableToProcessText;
  }else{
    return '';
  }
}
