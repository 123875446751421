import { Component, Vue } from 'vue-property-decorator'
import { sum } from 'lodash';
import router from '@/router';
import { BusyService } from './BusyService';
import ConfirmService from './ConfirmService';
import { ApiService } from './ApiService';
import { RequestType, EntryStage, RequestStatus } from '@/configs/SongRegistrationConfig';
import { RegisterSongDetails } from '@/models/RegisterSongDetails';
import { FERegisterSongDetails } from '@/models/frontendOnly/FERegisterSongDetails';
import { FELyricPart } from '@/models/frontendOnly/FELyricPart';
import { TerritoryService } from './TerritoryService';
import { RightshareSetDetails } from '@/models/RightshareSetDetails';
import { FERightshareSetDetails } from '@/models/frontendOnly/FERightshareSetDetails';
import { i18n } from '@/main';
import { ProfileService } from './ProfileService';
import Directives from '@/assets/json/Directives';
import { Lyric } from '@/models/Lyric';


@Component
class RegisterSongServiceClass extends Vue {
  maxLineLength = 52;

  editMode = false;
  checkChordsDiffer = false;
  requireEditChordsAfter = false;
  loaded = false;
  closeFn: null | (() => void) = null;

  secondModalOpened = false;
  overrideNavGuardOnce = false;

  setSecondModalOpened(opened: boolean){
    this.secondModalOpened = opened;
  }

  enableNavGuard () {
    if (this.overrideNavGuardOnce){
      this.overrideNavGuardOnce = false;
      return false;
    }
    return this.data && this.loaded && (JSON.stringify(this.data) !== this.origDataJSON)
  }

  data: FERegisterSongDetails | null = null;

  newRegisterSongDetails(): FERegisterSongDetails {
    return {
      requestId: '',
      currentStage: EntryStage.SelectCatalog,
      requestType: RequestType.RegisterSong,
      rightshareSetName: '',
      rightshareSetVid: '',
      rightshareSetDetails: [],
      spiRighshareSetDetails: [],
      songTitle: '',
      preText: '',
      songWriters: [],
      songLanguageLid: '-1',
      songLanguageDisplay: '',
      songCultureLid: '-1',
      songCultureDisplay: '',
      lyrictext: [],
      songType: '',
      ccliSongNumber: '',
      requestStatus: RequestStatus.Saved,
      isPossibleDuplicate: false,
      hasRestrictedWords: false,
      enablePunctuationOverride: false,
      lyrics: {
        lyricsId: '',
        songId: '',
        lyrics_Chorus: '',
        lyrics_Verse: '',
        lyrics_Misc: '',
        lyrics_DisplaySequence: '',
        dateCreated: null,
        dateModified: null,
        lyricParts: []
      },
      chords: '',
      url: [],
      isrc: []
    }
  }

  step = 4;
  showProgressBar = false;
  origDataJSON  = '';

  get previewChords(){
    let chords = this.data?.chords || '';
    if (chords){

      if (chords.includes('{title')){
        chords = chords.replaceAll(new RegExp(`{\\s*\\btitle\\b\\s*:?\\s*([^}{]*)}`, 'gi'),'');
      }
      if (chords.includes('{author')){
        chords = chords.replaceAll(new RegExp(`{\\s*\\bauthor\\b\\s*:?\\s*([^}{]*)}`, 'gi'),'');
      }

      chords =  `{title: ${this.data?.songTitle || 'Untitled'}}\n` +
                `{author: ${this.data?.songWriters.map(s => s.displayName).join(', ') || 'n/a'}}\n` +
                chords;
    }
    return chords;
  }

  removeOverwrittenDirectives(_chords: string) {
    let chords = _chords;
    const directives = Directives.filter(d => d.rules.overwrittenDuringEditing);
    directives.forEach(d => {
      chords = chords.replaceAll(new RegExp(`{\\s*\\b${d.tag}\\b\\s*:?\\s*([^}{]*)}`, 'gi'),'');
    })


    //replace all triple newlines with a double newline instead.
    chords = chords.replaceAll(/\n\s*\n\s*\n/gi, '\n\n');
    chords = chords.trim();
    return chords;
  }

  convertFEDetails(data: RegisterSongDetails){
    let chords = data.chords;

    if (chords){
      chords = this.removeOverwrittenDirectives(chords);
    }

    let lyricsConverted: FELyricPart[] = []
    try{
      lyricsConverted =  data.lyrictext ? JSON.parse(data.lyrictext)?.map((l: {
        LyricPartType: string,
        LyricPartId: string,
        LyricPartTypeLid: number,
        LyricPartCounter: number,
        LyricPartText: string[],
      }) => ({
        LyricPartType: l.LyricPartType,
        LyricPartId: l.LyricPartId,
        LyricPartTypeLid: l.LyricPartTypeLid.toString(),
        LyricPartCounter: l.LyricPartCounter,
        LyricPartText: l.LyricPartText,
        LyricPartTextAll: l.LyricPartText.join("\n"),
        validationErrors: [],
      })) || [] : [];
    }catch(err){ }

    const convertedData: FERegisterSongDetails = {
      requestId: data.requestId,
      currentStage: data.currentStage,
      requestType: data.requestType,
      rightshareSetName: data.rightshareSetName,
      rightshareSetVid: data.rightshareSetVid,
      rightshareSetDetails: this.convertToFERightshareSetDetails(data.rightshareSetDetails),
      spiRighshareSetDetails: null,
      songTitle: data.songTitle,
      preText: data.preText,
      songWriters: data.songWriters,
      songLanguageLid: data.songLanguageLid?.toString() || '',
      songLanguageDisplay: data.songLanguageDisplay,
      songCultureLid: data.songCultureLid?.toString() || '',
      songCultureDisplay: data.songCultureDisplay,
      lyrictext: lyricsConverted,
      ccliSongNumber: data.ccliSongNumber,
      requestStatus: data.requestStatus,
      isPossibleDuplicate: data.isPossibleDuplicate,
      hasRestrictedWords: data.hasRestrictedWords,
      lyrics: data.lyrics,
      songType: data.songType,
      enablePunctuationOverride: data.enablePunctuationOverride,
      chords: chords,
      isrc: data.isrc || [],
      url: data.url || []
    }

    return convertedData;
  }

  updateCultureSettings() {
    if (!this.cultures || !this.data){
      throw new Error('Culture/Data not available for updateCultureSettings');
    }
    this.data.songCultureDisplay = this.cultures.find(c => c.code === this.data?.songCultureLid)?.label || '';

    this.data.songLanguageDisplay = this.data.songCultureDisplay;
    this.data.songLanguageLid = this.data.songCultureLid
  }

  updateOnSongType() {
    if (this.data?.songType === 'Words & Music'){
      return;
    }
    else if (this.data?.songType === 'Words Only'){
      this.data?.songWriters.forEach(s => {
        s.wroteMusic = false;
        s.wroteWords = true;
      });
    }
    else if (this.data?.songType === 'Music Only'){
      this.data?.songWriters.forEach(s => {
        s.wroteMusic = true;
        s.wroteWords = false;
      });
    }
  }

  convertFromFERightshareSetDetails(data: FERightshareSetDetails[]): RightshareSetDetails[]{
    const converted: RightshareSetDetails[] = data.map(row => ({
      rightshareSetName: row.rightshareSetName,
      rightshareSetVid: row.rightshareSetVid,
      rightsharePercent: parseFloat(row.rightsharePercent),
      rightsharePercentRemaining: parseFloat(row.rightsharePercentRemaining),
      rightshareSetIsEditable: row.rightshareSetIsEditable,
      rightsHolderVid: row.rightsHolderVid,
      rightsHolderName: row.rightsHolderName,
    }))
    return converted;
  }

  convertToFERightshareSetDetails(data: RightshareSetDetails[]): FERightshareSetDetails[]{
    const converted: FERightshareSetDetails[] = data.map(row => ({
      rightshareSetName: row.rightshareSetName,
      rightshareSetVid: row.rightshareSetVid,
      rightsharePercent: row.rightsharePercent.toString(),
      rightsharePercentRemaining: row.rightsharePercentRemaining.toString(),
      rightshareSetIsEditable: row.rightshareSetIsEditable,
      rightsHolderVid: row.rightsHolderVid,
      rightsHolderName: row.rightsHolderName,
    }))
    return converted;
  }

  async save(updateRoute = true, isPossibleDuplicate = false, nextCurrentStage: EntryStage | null = null){
    if (!this.data){
      return;
    }
    BusyService.showBusy();

    this.updateLyricPartCounters();
    this.updateLyricPartText();
    this.updateCultureSettings();
    this.updateOnSongType();

    const lyrics:{
      LyricPartType: string,
      LyricPartId: string,
      LyricPartTypeLid: number,
      LyricPartCounter: number,
      LyricPartText: string[],
    }[] = this.data.lyrictext.map( l => ({
      LyricPartType: l.LyricPartType,
      LyricPartId: l.LyricPartId,
      LyricPartTypeLid: parseInt(l.LyricPartTypeLid),
      LyricPartCounter: l.LyricPartCounter,
      LyricPartText: l.LyricPartText,
    }));

    const submitData: RegisterSongDetails = {
      requestId: this.data.requestId,
      currentStage: nextCurrentStage || this.data.currentStage,
      requestType: this.data.requestType,
      rightshareSetName: this.data.rightshareSetName,
      rightshareSetVid: this.data.rightshareSetVid,
      rightshareSetDetails: this.convertFromFERightshareSetDetails(this.data.rightshareSetDetails),
      spiRighshareSetDetails: null,
      songTitle: this.data.songTitle,
      preText: this.data.preText,
      songWriters: this.data.songWriters,
      songLanguageLid: parseInt(this.data.songLanguageLid),
      songLanguageDisplay: this.data.songLanguageDisplay,
      songCultureLid: parseInt(this.data.songCultureLid),
      songCultureDisplay: this.data.songCultureDisplay,
      lyrictext: JSON.stringify(lyrics),
      ccliSongNumber: this.data.ccliSongNumber,
      requestStatus: this.data.requestStatus,
      isPossibleDuplicate: isPossibleDuplicate || this.data.isPossibleDuplicate,
      hasRestrictedWords: this.data.hasRestrictedWords,
      lyrics: this.data.lyrics,
      songType: this.data.songType,
      enablePunctuationOverride: this.enablePunctuationOverrideFeature && this.data.enablePunctuationOverride || false,
      chords: this.data.chords,
      isrc: this.data.isrc,
      url: this.data.url
    };

    (window as any).submitData = submitData;
    (window as any).submitDataJSON = JSON.stringify(submitData);

    if (this.editMode){
      const response = await ApiService.post('/api/UpdateSong', {
        data: JSON.stringify(submitData),
        territoryId: parseInt(TerritoryService.territoryId),
        songNumber: parseInt(submitData.ccliSongNumber),
      }, true) as RegisterSongDetails;

      this.data = this.convertFEDetails(response);


    }else{
      const data = await ApiService.post('/api/SaveRegisterSongData', {data: JSON.stringify(submitData)}, true) as RegisterSongDetails;
      if (updateRoute){
        this.data = this.convertFEDetails(data);
        this.validateLyricParts();
        if (this.data && router.currentRoute.params.id !== this.data.requestId){
          router.replace(`/intellectualproperty/registersongs/${this.data.requestId}`);
        }
      }
      this.origDataJSON = JSON.stringify(this.data);
    }


    BusyService.hideBusy();
  }

  async saveAndContinue(isPossibleDuplicate = false){
    if (!this.data){
      return;
    }
    let nextCurrentStage = this.data.currentStage;
    if (this.data.songType === 'Music Only' && this.data.currentStage === EntryStage.EnterSongDetails){
      nextCurrentStage = EntryStage.Review;
    }else if (this.data.currentStage === EntryStage.EnterLyrics && !ProfileService.profile?.featureToggleChords){
      nextCurrentStage = EntryStage.Review;
    }else if (this.data.currentStage === EntryStage.EnterLyrics && this.data.songType === 'Words Only'){
      nextCurrentStage = EntryStage.Review;
    }else{
      nextCurrentStage++;
      nextCurrentStage = Math.min(EntryStage.Review, nextCurrentStage);
    }
    if (!this.editMode){
      await this.save(true, isPossibleDuplicate, nextCurrentStage);
      this.data.currentStage = nextCurrentStage; // will not occur if there was an error in the request
    }
  }

  async submitSong() {
    if (!this.data){
      return;
    }
    BusyService.showBusy();
    const ccliSongNumber = await ApiService.post<string>('/api/RegisterSong', {requestId: this.data.requestId});
    this.data.currentStage = EntryStage.Done;
    this.data.ccliSongNumber = ccliSongNumber;
    this.origDataJSON = JSON.stringify(this.data);
    BusyService.hideBusy();
  }

  async saveAndExit(){
    await this.save(false);
    this.exit();
  }

  async exit(){
    if (!this.editMode){
      router.push('/intellectualproperty/registersongs').catch(() => {})
    }else{
      if (this.enableNavGuard() && !this.editMode){
        const success = await ConfirmService.showConfirm({
          title: i18n.t('R.LIT_UnsavedChanges').toString(),
          message: i18n.t('R.MSG_ExitWithoutSaving').toString(),
          okLabel: i18n.t('R.LIT_ContinueWithoutSaving').toString(),
          cancelLabel: i18n.t('G.LIT_Cancel').toString()
        })
        if (success){
          this.closeFn && this.closeFn();
        }
      }else{
        this.closeFn && this.closeFn();
      }
    }
  }

  newMode = false;

  async loadSong(requestId: string){
    BusyService.showBusy();
    this.editMode = false;
    this.loaded = false;
    this.data = null;
    this.requireEditChordsAfter = false;

    this.closeFn = null;

    if (requestId === 'new'){
      this.registerNewSong();
      this.loaded = true;
    }else{
      try{
        const data = await ApiService.post<RegisterSongDetails>('/api/GetRegisterSongData', { requestId });
        this.data = this.convertFEDetails(data);
        this.validateLyricParts();
        this.loaded = true;

      }catch(err){
        this.loaded = true;
        throw err;
      }

      this.origDataJSON = JSON.stringify(this.data);
    }

    //get song from api
    BusyService.hideBusy();
    if (!this.data){
      return;
    }
    if (this.data.currentStage >= EntryStage.Done){
      this.showProgressBar = false;
    }else{
      this.showProgressBar = true;
    }
  }

  async loadEditSong(ccliSongId: string, requestType: RequestType, onCloseFn?: () => void){
    this.closeFn = onCloseFn || null;
    this.editMode = true;
    this.requireEditChordsAfter = false;
    const data = await ApiService.post<RegisterSongDetails>('/api/EditSong', {
      territoryId: parseInt(TerritoryService.territoryId),
      songNumber: ccliSongId,
      requestType: requestType
    });
    this.data = this.convertFEDetails(data);
    this.validateLyricParts();
    this.origDataJSON = JSON.stringify(this.data);
    this.loaded = true;
  }

  registerNewSong() {
    this.data = this.newRegisterSongDetails();
    this.showProgressBar = true;
    this.requireEditChordsAfter = false;
    this.origDataJSON = JSON.stringify(this.data);
  }

  get totalPercent(){
    if (!this.data){
      return 0;
    }
    return Math.round(sum(this.data.rightshareSetDetails.map(c => parseFloat(c.rightsharePercent || '0')))*10000)/10000;
  }

  get unclaimedPercent(){
    return Math.round((100 - this.totalPercent)*10000)/10000;
  }



  updateLyricPartText(){
    if (!this.data){
      return;
    }
    this.data.lyrictext.forEach(part => {
      part.LyricPartText = part.LyricPartTextAll.split('\n').map(l => l.trim())
    });
  }

  validateLyricParts(){
    if (!this.data){
      return;
    }
    this.updateLyricPartText();
    this.data.lyrictext.forEach(part => {
      part.validationErrors = [];
      const lineLengths = part.LyricPartText.map(l => l.length)
      if (Math.max(...lineLengths) > this.maxLineLength){
        part.validationErrors.push(i18n.t('R.LIT_LyricMaxCharacterLineLimit', {0: this.maxLineLength.toString()}).toString());
      }
      if (part.LyricPartTextAll.match(/[<>]/g)){
        part.validationErrors.push(i18n.t('R.MES_InvalidLyricCharacter').toString());
      }
    })
  }

  updateLyricPartCounters(){
    if (!this.data){
      return;
    }
    const partTypes = this.data.lyrictext.map(p => p.LyricPartTypeLid);
    const partTypeCounts = partTypes.map( (p, i) => partTypes.slice(0,i + 1).filter(subsetItem => subsetItem === p).length );
    this.data.lyrictext.forEach((part, index) =>{
      part.LyricPartCounter = partTypeCounts[index];
    });
  }

  cultures: { code: string, label: string }[] | null = null;

  async getCultures() {
    if (!this.cultures){
      this.cultures = await ApiService.post('/api/GetCultures') as { code: string, label: string}[];
    }
  }

  get enablePunctuationOverrideFeature(){
    if (!ProfileService.profile?.org.enablePunctuationOverrideForLanguages || ProfileService.profile?.org.enablePunctuationOverrideForLanguages.length === 0 || !this.data){
      return false;
    }
    return ProfileService.profile.org.enablePunctuationOverrideForLanguages.includes(this.data.songCultureLid);
  }

  generateInitialChords(data:{ key: string, tempo: string, time: string}, lyricText: FELyricPart[]) {
    const parts: {[part: string]: number} = {};
    const lyrics = lyricText.map(l => {
      const showCountForPart = lyricText.filter(p => p.LyricPartType === l.LyricPartType).length > 1;

      if (showCountForPart){
        if (!parts[l.LyricPartType]){
          parts[l.LyricPartType] = 0;
        }
        parts[l.LyricPartType] ++;
        return `{comment: ${l.LyricPartType} ${parts[l.LyricPartType]}}\n${l.LyricPartTextAll}`
      }else{
        return `{comment: ${l.LyricPartType}}\n${l.LyricPartTextAll}`
      }
    }).join('\n\n');

    return  `{key: ${data.key}}
{tempo: ${data.tempo}}
{time: ${data.time}}

${lyrics}`;
  }



  getLyricsRef (lyrics: FELyricPart[]){
    return lyrics
      .map(l => l.LyricPartTextAll)
      .join('\n')
      .replaceAll(/\s*-\s*/gi,'')
      .trim()
      .split('\n')
      .map(l => l.trim().replaceAll(/\s{2,}/gi, ' '))
      .join('\n');
  }

  getLyricsFromCSE(chords: string) {
    return chords
      .replaceAll(/{[^}]*}/gi, '')
      .replaceAll(/\[[^\]]*\]/gi, '')
      .replaceAll(/\s*-\s*/gi,'')
      .replaceAll(/\n{2,}/gi, '\n')
      .trim()
      .split('\n')
      .map(l => l.trim().replaceAll(/\s{2,}/gi, ' '))
      .join('\n');
  }

  get lyricsDiffer() {
    if (!this.data) {
      return false;
    }
    return this.getLyricsRef(this.data.lyrictext) !== this.getLyricsFromCSE(this.data.chords);
  }


}

export const RegisterSongService = new RegisterSongServiceClass()
