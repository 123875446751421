
import { Component, Vue, Prop } from "vue-property-decorator";
import FForm from '@/components/Form/FForm.vue';
import FInput from '@/components/Form/FInput.vue';
import FSelect from '@/components/Form/FSelect.vue';
import FCheckbox from '@/components/Form/FCheckbox.vue';
import FButton from '@/components/Button/FButton.vue';
import ModalForm from '@/components/Modal/ModalForm.vue';
import Modal from '@/components/Modal/Modal.vue';
import Alert from '@/components/Alert/Alert.vue';
import PhraseLink from '@/components/PhraseLink/PhraseLink.vue';
import RegisterSongStepButtons from './RegisterSongStepButtons.vue';
import { RegisterSongService } from "@/services/RegisterSongService";
import { DuplicateSong } from '@/models/DuplicateSong';
import { SelectOption } from "@/models/SelectOption";
import { Contributor } from "@/models/Contributor";
import { ApiService } from "@/services/ApiService";
import { debounce, DebouncedFunc } from "lodash";
import { ToastService } from "@/services/ToastService";
import { BusyService } from "@/services/BusyService";
import { i18n } from "@/main";
import { CustomError } from "@/models/CustomError";
import { ProfileService } from "@/services/ProfileService";
import { SongDetails } from "@/models/SongDetails";
import HelpIcon from "@/components/HelpIcon/HelpIcon.vue";
import MultipleItemsInput from "./MultipleItemsInput.vue";

@Component({
  components:{
    Alert,
    FForm,
    FInput,
    FSelect,
    FCheckbox,
    FButton,
    ModalForm,
    Modal,
    PhraseLink,
    RegisterSongStepButtons,
    MultipleItemsInput,
    HelpIcon
  },
})
export default class RegisterSongStep1EnterSongDetails extends Vue {
  @Prop() song!: SongDetails | null;
  store = RegisterSongService;
  specialCharPattern = /[<>]/;

  get canEditOtherDetails(){
    return this.song && this.song.otherSongDetailsCanBeEdited && !this.song.songDetailsCanBeEdited;
  }

  get editMode() {
    return this.store.editMode;
  }

  get featureToggleChords() {
    return ProfileService.profile?.featureToggleChords;
  }

  removeIndex = 0;

  get removeSongWriterName() {

    if (!this.store.data){
      return '';
    }
    if (!this.store.data.songWriters){
      return '';
    }
    if (this.removeIndex >= 0 && this.removeIndex < this.store.data.songWriters.length){
      return this.store.data.songWriters[this.removeIndex].displayName;
    }else{
      return '';
    }
  }

  get newSongWriterHasSpecialChars() {
    return this.specialCharPattern.test(this.newSongWriter.firstName) || this.specialCharPattern.test(this.newSongWriter.lastName);
  }

  get songTitleHasSpecialChars() {
    return this.specialCharPattern.test(this.store.data?.songTitle || '');
  }

  onRemove(index: number){
    this.removeIndex = index;
    (this.$refs.confirmRemoveSongwriterModal as Modal).show();
  }

  removeSongwriter(){
    this.store.data?.songWriters?.splice(this.removeIndex, 1);
    (this.$refs.confirmRemoveSongwriterModal as Modal).hide();
  }

  duplicatePayload: DuplicateSong []| null = null;
  restrictedWordsError = false;
  stringInError = '';
  error = false;

  onSubmitErrorHandling(err: any){

    if ((err as any).data.publicStatusMessage === 'DUPLICATE_FOUND'){
      this.duplicatePayload = (err as any).data.payload;
      (this.$refs.confirmDuplicateSongModal as Modal).show();
    }else if ((err as any).data.publicStatusMessage === 'RESTRICTED_WORDS'){
      this.restrictedWordsError = true;
      if ((err as any)?.data?.payload){
        if (typeof (this.stringInError = (err as any).data.payload) === 'string'){
          this.stringInError = (err as any).data.payload;
        }else {
          this.stringInError = (err as any).data.payload.join(', ');
        }
      }
    }else{
      this.error = true;
      if ((err as any).data.publicStatusMessage.length > 0){
        ToastService.dangerToast((err as any).data.publicStatusMessage);
      } else {
        ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
      }
      throw new Error('Reason code not recognized');
    }
  }


  async onSubmit(isPossibleDuplicate  = false) {
     if ((this.$refs.form as FForm).hasFormError || this.songTitleHasSpecialChars ){
       return;
     }
     if (this.editMode){
       this.$emit('submit', isPossibleDuplicate);
     }else{
      try{
        await this.store.saveAndContinue(isPossibleDuplicate);
      }catch(err){
        this.onSubmitErrorHandling(err);
      }
     }
  }

  debounceSongWriterSearch: DebouncedFunc<() => void> | null = null;

  showSuggestions = false;

  mounted(){
    this.debounceSongWriterSearch = debounce(this.onSongwriterSearchInput, 300);
    document.addEventListener('click', this.onDocumentClick);
  }
  beforeDestroy() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  get songTypeOptions(): SelectOption[] {
    return [
      {
        value: 'Words & Music',
        label: i18n.t('R.LIT_WordsAndMusic').toString(),
      }, {
        value: 'Words Only',
        label: i18n.t('R.LIT_WordsOnly').toString(),
      }, {
        value: 'Music Only',
        label: i18n.t('R.LIT_MusicOnly').toString(),
      }
    ]
  }

  songwriterSearch = '';
  searchingSongWriter = false;
  suggestions: Contributor[] | null = null; // null means not yet searched where as [] means empty results
  async onSongwriterSearchInput(){
    this.searchingSongWriter = true;
    this.suggestions = await ApiService.post('/api/GetPartyContributors', {
      range: !this.songwriterSearch ? 2: 4, // 2 for 'party', 4 for 'suggest'
      suggestion: this.songwriterSearch,
      rightshareSetDetails: JSON.stringify(this.store.data?.rightshareSetDetails)
    }) || [];


    const partyIds = this.store.data?.songWriters?.map( s=> s.partyId) || [];
    
    if (this.suggestions && this.suggestions.length){
      this.suggestions = this.suggestions.filter(s => 
      !partyIds.includes(s.id) && !this.specialCharPattern.test(s.displayName)
    );
    }
    this.showSuggestions = true;
    this.searchingSongWriter = false;
  }

  onDocumentClick(e: MouseEvent) {
    if (this.showSuggestions && !(e.target as HTMLElement).closest('#SongwriterClickArea')){
      this.showSuggestions = false;
    }
  }

  onSelectSongWriter(songwriterSuggestion: Contributor, originallyFromSpi = true){
    this.store.data?.songWriters.push({
      displayName: songwriterSuggestion.displayName,
      partyId: songwriterSuggestion.id,
      wroteWords: this.store.data?.songType === 'Words & Music' || this.store.data?.songType === 'Words Only',
      wroteMusic: this.store.data?.songType === 'Words & Music' || this.store.data?.songType === 'Music Only',
      originallyFromSpi,
    })

    this.songwriterSearch = '';
    this.showSuggestions = false;
  }

  openRegisterNewSongWriterModal() {
    this.newSongWriter.error = '';
    this.newSongWriter.stringInError = '';
    this.newSongWriter.firstName = '';
    this.newSongWriter.lastName = '';
    this.newSongWriter.restrictedWordsError = false;
    this.newSongWriter.hasSpecialChars = false;
    (this.$refs.modal as ModalForm).show();
  }

  newSongWriter = {
    error: '',
    stringInError: '',
    restrictedWordsError: false,
    hasSpecialChars: false,
    firstName: '',
    lastName: '',
  }

  async registerDuplicateSongwriter() {
    this.registerNewSongwriter(true);
    (this.$refs.confirmDuplicateOverride as Modal).hide();
  }
  async registerNewSongwriter(duplicateOverwrite = false) {
    if (this.newSongWriterHasSpecialChars) {
      this.newSongWriter.hasSpecialChars = true;
      return;
    } else {
      this.newSongWriter.hasSpecialChars = false;
    }

    // account for duplicate name cases DUPLICATE_FOUND, DUPLICATE_BUT_OWNER
    // account for restricted words case RESTRICTED_WORDS

    BusyService.showBusy();
    try{

      const contributor = await ApiService.post('/api/RegisterSongContributor', {
        firstName: this.newSongWriter.firstName,
        primaryName: this.newSongWriter.lastName,
        rightshareSetId: this.store.data?.rightshareSetDetails[0]?.rightshareSetVid,
        duplicateOverwrite,
      }, true) as Contributor;

      this.onSelectSongWriter(contributor, false);

      BusyService.hideBusy();
      (this.$refs.modal as ModalForm).hide();
      ToastService.successToast(this.$t('R.LIT_CreatedNewSongwriter').toString())

    }catch(err) {
      BusyService.hideBusy();
      if ((err as any)?.data?.payload){
        if (typeof (this.newSongWriter.stringInError = (err as any).data.payload) === 'string'){
          this.newSongWriter.stringInError = (err as any).data.payload;
        }else {
          this.newSongWriter.stringInError = (err as any).data.payload.join(', ');
        }
      }else{
        ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
        throw new CustomError(err, false, 'Reason code not recognized');
      }


      this.newSongWriter.error = (err as any).data.publicStatusMessage;

      if ((err as any)?.data?.publicStatusMessage === 'RESTRICTED_WORDS'){
        this.newSongWriter.restrictedWordsError = true;
      }else if ((err as any)?.data?.publicStatusMessage === 'DUPLICATE_FOUND'){
        (this.$refs.confirmDuplicateOverride as Modal).show();
      }else{
        ToastService.dangerToast(i18n.t('R.MES_ServerError').toString())
        throw new CustomError(err, false, 'Reason code not recognized');
      }
    }
  }

  confirmDuplicateSong(){
    (this.$refs.confirmDuplicateSongModal as Modal).hide();
    this.onSubmit(true);
  }

}
